<template>
  <div class="modal-manager" @click.self="handleCloseModal" @keydown.enter="handleCloseModal">
    <div class="modal-manager__content" :class="[type]">
      <component
        :is="type"
        :content="content"
        ref="content"
        @close="handleCloseModal"
      />
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';
import { mapGetters, mapState } from 'vuex';
import {
  APP_EVENTS,
  MODAL_TYPES,
  VIDEO_EVENTS,
} from '@/core/constants';
import eventBus from '@/core/eventBus';
import ModalCarousel from './ModalCarousel.vue';
import EndScene from './EndScene.vue';

export default {
  name: 'ModalManager',
  components: {
    ModalCarousel,
    EndScene,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    content: {
      required: true,
    },
  },
  setup() { return { tracking: inject('tracking') }; },
  computed: {
    ...mapState('video', ['videoActive']),
    ...mapState(['activeHotspotDot']),
    ...mapGetters(['displayIndex']),
    trackingLabel() {
      return `/devotion/${this.displayIndex}/scene/${this.activeHotspotDot}`;
    },
  },
  methods: {
    handleCloseModal() {
      if (this.type === MODAL_TYPES.CAROUSEL) {
        this.$refs.content.fadeAudio();
        this.tracking.trackEvent('e-carousel-close', { eventLabel: this.trackingLabel });
      }
      if (this.type === MODAL_TYPES.END_SCENE) {
        this.tracking.trackEvent('e-bottle-scene-close');
        if (this.videoActive) eventBus.emit(VIDEO_EVENTS.PLAY_VIDEO);
      }
      eventBus.emit(APP_EVENTS.HIDE_MODAL);
    },
  },
};
</script>

<style lang="scss">
.modal-manager {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  z-index: 1000;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 19px;
  background: rgba(black, 0.85);
  @media screen and (min-width: 768px) {
    padding: 40px 38px;
  }
  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    &.EndScene {
      overflow: hidden;
    }
  }
}
</style>
