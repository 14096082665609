const initialVideoTrackingComplete = {
  0: false,
  25: false,
  50: false,
  75: false,
  100: false,
};

export default {
  data: () => ({
    videoTrackingComplete: {
      0: false,
      25: false,
      50: false,
      75: false,
      100: false,
    },
    videoTrackingId: '',
    videoTrackingLabel: '',
    currentTime: 0,
    videoDuration: 0,
  }),
  methods: {
    trackVideo(num) {
      this.videoTrackingComplete[num] = true;
      this.tracking.trackEvent(this.videoTrackingId, {
        eventAction: `Playing ${num}%`,
        eventLabel: this.videoTrackingLabel,
      });
    },
    checkTrackVideo() {
      const completion = (this.currentTime / this.videoDuration);
      if (!this.videoTrackingComplete[0] && completion >= 0) this.trackVideo(0);
      if (!this.videoTrackingComplete[25] && completion >= 0.25) this.trackVideo(25);
      if (!this.videoTrackingComplete[50] && completion >= 0.50) this.trackVideo(50);
      if (!this.videoTrackingComplete[75] && completion >= 0.75) this.trackVideo(75);
      if (!this.videoTrackingComplete[100] && completion >= 0.99) this.trackVideo(100);
    },
    resetVideoTracking() {
      this.videoTrackingComplete = { ...initialVideoTrackingComplete };
    },
  },
};
