import getTypesForScope from '../utils';

const types = getTypesForScope('canvas');

const moduleState = {
  currentCanvasIndex: 0,
  canvasReady: false,
  canvasActive: false,
  renderCanvas: false,
  ignoreCanvasOut: false,
};

const mutations = {
  [types.SET_CANVAS_INDEX](state, val) {
    state.currentCanvasIndex = val;
  },
  [types.SET_CANVAS_RENDER](state, bool) {
    state.renderCanvas = bool;
  },
  [types.SET_CANVAS_READY](state, bool) {
    state.canvasReady = bool;
  },
  [types.SET_CANVAS_ACTIVE](state, bool) {
    state.canvasActive = bool;
  },
  [types.SET_CANVAS_IGNORE_OUT](state, bool) {
    state.ignoreCanvasOut = bool;
  },
};

const actions = {
  setCanvasRender({ commit }, bool) {
    commit(types.SET_CANVAS_RENDER, bool);
  },
  setCanvasActive({ commit }, bool) {
    commit(types.SET_CANVAS_ACTIVE, bool);
  },
  setCanvasReady({ commit }, bool) {
    commit(types.SET_CANVAS_READY, bool);
  },
  setCanvasIndex({ commit }, val) {
    commit(types.SET_CANVAS_INDEX, val);
  },
  setCanvasIgnoreOut({ commit }, val) {
    commit(types.SET_CANVAS_IGNORE_OUT, val);
  },
};

export default {
  namespaced: true,
  state: moduleState,
  mutations,
  actions,
};
