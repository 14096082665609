<template>
  <div class="roadblock">
    <video
      class="roadblock__background"
      :src="roadblockSrc"
      autoplay="autoplay"
      playsinline="playsinline"
      muted="muted"
    ></video>
    <span class="roadblock__icon" v-html="RoadblockSvg" />
  </div>
</template>

<script>
import { ASSET_BASE_URL } from '@/core/constants';
import RoadblockSvg from '@/assets/svg/roadblock.svg';

export default {
  name: 'roadblockView',
  data: () => ({
    RoadblockSvg,
  }),
  computed: {
    roadblockSrc() {
      return `${ASSET_BASE_URL}roadblock.mp4`;
    },
  },
};
</script>

<style lang="scss">
.roadblock {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: black;
  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
  &__icon {
    position: relative;
    z-index: 2;
  }
}
</style>
