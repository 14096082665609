<template>
  <div class="recipe-tabs">
    <div class="recipe-tabs__background" @click="$emit('close')" @keydown.enter="$emit('close')" />
    <div class="recipe-tabs__wrapper">
      <div class="recipe-tabs__tabs" :class="[tequilaName]">
        <div class="recipe-tabs__tabs__buttons">
          <button
            class="recipe-tabs__tabs__buttons__button"
            v-for="(recipe, i) in recipes"
            :key="recipe.name"
            :class="[tequilaName, { active: i === activeRecipeIndex }]"
            :style="i === activeRecipeIndex && activeTabStyle"
            @click="handleChooseRecipe(i)"
            @keydown.enter="handleChooseRecipe(i)"
          >
            {{recipe.name}}
          </button>
          <button class="recipe-tabs__tabs__buttons__close" @click="$emit('close')">
            CLOSE
            <span v-html="Close" />
          </button>
        </div>
        <div class="recipe-tabs__tabs__recipe" :class="[tequilaName]">
          <img
            :src="recipeBG"
            alt=""
            class="recipe-tabs__tabs__recipe__bg"
            @load="backgroundLoaded = true;"
          />
            <div class="recipe-tabs__tabs__recipe__content" ref="content">
              <span class="recipe-logo" v-html="Logo" />
              <div class="recipe-body-wrap" :key="`${activeRecipe.name} body`">
                <FadeQuote
                  :copy="activeRecipe.name"
                  small
                  ref="fadeQuote"
                  :key="`${activeRecipe.name} quote`"
                />
                <div class="recipe-rule" :key="`${activeRecipe.name} rule`" />
                <img
                  class="recipe-image"
                  :src="cocktailURL"
                  :key="`${activeRecipe.name} image small`"
                  :alt="activeRecipe.name"
                  @load="cocktailLoaded = true;"
                />
                <div class="recipe-body">
                  <p v-if="activeRecipe.description">{{activeRecipe.description}}</p>
                  <p class="large">Ingredients</p>
                  <p
                    class="ingredient"
                    v-for="(ingredient, i) in activeRecipe.ingredients"
                    v-html="ingredient"
                    :class="{ first: i === 0, last: i === activeRecipe.ingredients.length - 1 }"
                    :key="ingredient"
                  />
                  <p v-if="activeRecipe.garnish">
                    <strong>Garnish:</strong>
                    {{activeRecipe.garnish}}
                  </p>
                  <p>
                    <strong>Glass:</strong>
                    {{activeRecipe.glass}}
                  </p>
                  <p class="large">Directions</p>
                  <ol class="directions">
                    <li
                      class="direction"
                      v-for="(direction, i) in activeRecipe.directions"
                      v-html="direction"
                      :class="{ first: i === 0, last: i === activeRecipe.ingredients.length - 1 }"
                      :key="direction"
                    />
                  </ol>
                  <p class="large">Act of Devotion</p>
                  <p>{{activeRecipe.devotion}}</p>
                </div>
              </div>
            </div>
        </div>
        <div class="recipe-tabs__tabs__image">
          <img
            v-for="(recipe, index) in recipes"
            :key="`${recipe.name} image large`"
            :class="{ active: index === activeRecipeIndex }"
            :src="`${baseUrl}endScene/cocktails/${tequilaName}-${index}.jpg`"
            :alt="recipe.name"
            @load="cocktailImagesLoaded += 1;"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap';
import { mapState } from 'vuex';
import Close from '@/assets/svg/close.svg';
import Logo from '@/assets/svg/logoWhiteGold.svg';
import { ASSET_BASE_URL } from '@/core/constants';
import FadeQuote from './FadeQuote.vue';

export default {
  name: 'recipe-tabs',
  components: {
    FadeQuote,
  },
  props: {
    recipes: {
      type: Array,
      required: true,
    },
    tequilaName: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    Close,
    Logo,
    baseUrl: ASSET_BASE_URL,
    activeRecipeIndex: 0,
    cocktailLoaded: false,
    timeline: null,
    backgroundLoaded: false,
    cocktailImagesLoaded: 0,
  }),
  computed: {
    readyToAnimate() {
      if (this.viewWidth < 600) {
        return this.backgroundLoaded && this.cocktailLoaded;
      }
      return this.desktopCocktailsLoaded && this.backgroundLoaded;
    },
    desktopCocktailsLoaded() {
      return this.cocktailImagesLoaded === this.recipes.length;
    },
    activeRecipe() {
      return this.recipes[this.activeRecipeIndex];
    },
    cocktailURL() {
      return `${this.baseUrl}endScene/cocktails/${this.tequilaName}-${this.activeRecipeIndex}.jpg`;
    },
    recipeBG() {
      return `${this.baseUrl}endScene/recipe-backgrounds/${this.tequilaName}.jpg`;
    },
    activeTabStyle() {
      return {
        backgroundImage: `url(${this.baseUrl}endScene/recipe-backgrounds/${this.tequilaName}.jpg)`,
      };
    },
    ...mapState(['viewWidth']),
  },
  watch: {
    readyToAnimate(val) {
      if (!val) return;
      this.animateIn();
    },
    activeRecipeIndex() {
      if (this.viewWidth > 600) {
        this.$nextTick(this.animateIn);
      }
    },
  },
  methods: {
    handleImageLoaded() { this.imagesLoaded += 1; },
    handleChooseRecipe(index) {
      gsap.to(['.recipe-body-wrap'], {
        opacity: 0,
        duration: 0.3,
        ease: 'power2.out',
      });
      gsap.to(this.$refs.content, {
        scrollTop: 0,
        duration: 0.35,
        onComplete: () => { this.activeRecipeIndex = index; },
      });
      this.cocktailLoaded = false;
    },
    animateIn() {
      let delay = 0;
      if (!this.hasAnimatedOnce) {
        delay = 0.75;
        this.hasAnimatedOnce = true;
        gsap.to('.recipe-tabs__tabs__recipe__bg', { opacity: 1, ease: 'power2.out' });
        gsap.to('.recipe-logo', {
          opacity: 1,
          duration: 0.5,
          delay,
          ease: 'power2.out',
        });
      }
      gsap.delayedCall(delay + 0.25, this.$refs.fadeQuote.animIn);
      gsap.fromTo('.recipe-rule', { scaleX: 0 }, {
        scaleX: 1,
        duration: 0.5,
        ease: 'power2.out',
        delay: delay + 0.5,
      });
      if (this.viewWidth < 600) {
        gsap.to('.recipe-image', {
          opacity: 1,
          duration: 0.75,
          ease: 'power2.out',
          delay: delay + 1,
        });
      } else {
        gsap.set('.recipe-image', { opacity: 1 });
      }
      gsap.to('.recipe-body', {
        opacity: 1,
        duration: 0.5,
        delay: delay + 1,
        ease: 'power2.out',
      });
    },
  },
};
</script>

<style lang="scss">
.recipe-tabs {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 30px 20px;
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  top: 0;
  left: 0;
  @include bpMedium {
    padding: 40px 38px;
  }
  &__wrapper {
    width: 100%;
    height: 100%;
    position: relative;
  }
  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.75);
    z-index: 1;
  }
  &__tabs {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 90%;
    max-width: 800px;
    height: 85%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin-top: 25.5px;
    flex-direction: column;
    @include bpMedium {
      flex-direction: row;
      max-height: 532px;
      margin-top: 18px;
    }
    &::before {
      content: '';
      width: 100%;
      height: 100%;
      background: white;
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
    }
    @each $name, $colors in $recipeColors {
      &.#{$name} {
        &::before { background: map-get($colors, light); }
      }
    }
    &__buttons {
      position: absolute;
      top: 0;
      left: 0;
      transform: translateY(calc(-100% + 5px));
      display: flex;
      width: 100%;
      flex-wrap: nowrap;
      &__button {
        border: none;
        width: 100%;
        cursor: pointer;
        background: #F1F5E8;
        color: $black;
        border-radius: 8px 8px 0 0;
        padding: 10px 12px 15px;
        margin-left: 1px;
        transition:
          color 300ms $easeOutMaterial,
          transform 300ms $easeOutMaterial,
          background 300ms $easeOutMaterial;
        backface-visibility: hidden;
        z-index: -1;
        @include bpMedium {
          width: auto;
        }
        &:first-of-type { margin-left: 0; }
        @include on-hover {
          transform: translateY(-5px);
        }
        &.active {
          pointer-events: none;
          color: white;
          @each $name, $colors in $recipeColors {
            &.#{$name} {
              background: map-get($colors, light);
            }
          }
        }
      }
      &__close {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        background: none;
        border: none;
        font-size: 12px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 0;
        transform: translateY(-100%);
        -webkit-tap-highlight-color: transparent;
        @include bpMedium {
          transform: translateY(0%);
        }
        svg {
          width: 10px;
          height: 10px;
          fill: white;
          margin-left: 5px;
        }
      }
    }
    &__recipe, &__image {
      height: 100%;
      object-fit: cover;
      width: 100%;
      @include bpMedium {
        width: 50%;
        height: 100%;
      }
    }
    &__image {
      display: none;
      position: relative;
      z-index: 2;
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        transition: opacity 500ms $easeOutMaterial;
        opacity: 0;
        object-fit: cover;
        &.active {
          opacity: 1;
        }
      }
      @include bpMedium {
        display: block;
      }
    }
    &__recipe {
      position: relative;
      overflow: hidden;
      box-sizing: border-box;
      &::before {
        position: absolute;
        transform: rotate(-180deg);
        pointer-events: none;
        content: '';
        top: -1px;
        left: 0;
        height: calc(100% + 1px);
        width: 100%;
        z-index: 4;
      }
      @each $name, $colors in $recipeColors {
        &.#{$name} {
          &::before {
            background:
              linear-gradient(
                180deg,
                map-get($colors, light) 0%,
                rgba(map-get($colors, light), 0) 10.15%,
                rgba(map-get($colors, dark), 0) 95.79%,
                map-get($colors, dark) 100%
              );
          }
        }
      }
      .recipe-logo {
        width: 82px;
        height: 55px;
        flex-shrink: 0;
        opacity: 0;
      }
      &__bg {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
        opacity: 0;
      }
      &__content {
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        box-sizing: border-box;
        overflow: auto;
        padding: 30px 30px 60px;
        @include bpMedium {
          padding: 30px 30px 50px;
        }
        .recipe-body {
          opacity: 0;
        }
        .fade-quote {
          &__title {
            margin: 0;
          }
        }
        .recipe-image {
          display: block;
          width: 100%;
          object-fit: cover;
          opacity: 0;
          @include bpMedium {
            display: none;
          }
        }
        .recipe-rule {
          width: 100%;
          height: 1px;
          flex-shrink: 0;
          background: white;
          margin: 20px 0;
          transform-origin: center;
          transform: scaleX(0);
        }
        .directions {
          padding-left: 16px;
          width: 100%;
          .direction {
            color: white;
            text-transform: none;
            font-size: 14px;
            margin: 5px 0;
            &.first { margin-top: 0; }
          }
        }
        p {
          color: white;
          width: 100%;
          margin: 15px 0;
          text-align: left;
          text-transform: none;
          font-size: 14px;
          &.large {
            font-size: 16px;
            text-transform: uppercase;
            line-height: 21px;
          }
          &.ingredient {
            margin: 0;
            &.first { margin-top: 14px; }
            &.last { margin-bottom: 14px; }
          }
        }
      }
    }
  }
}
</style>
