export const EVENTS = {
  'p-loader': {
    event: 'pageView',
    pagePath: '/',
  },
  'p-hero-video': {
    event: 'pageView',
    pagePath: '/intro-video',
  },
  'p-intro': {
    event: 'pageView',
    pagePath: '/intro',
  },
  'p-devotion-chapter': {
    event: 'pageView',
    pagePath: '/devotion/<chapter number>',
  },
  'p-devotion-scene': {
    event: 'pageView',
    pagePath: '/devotion/<chapter number>/scene/<scene number>',
  },
  'p-tequilas': {
    event: 'pageView',
    pagePath: '/tequilas',
  },
  'p-tequilas-bottle': {
    event: 'pageView',
    pagePath: '/tequilas/<index>',
  },
  'e-intro-video-view': {
    event: 'customEvent',
    eventCategory: 'Video',
    eventAction: 'Playing <0|25|50|75|100>%',
    eventLabel: 'Intro Video',
  },
  'e-audio-1': {
    event: 'customEvent',
    eventCategory: 'In Page Buttons',
    eventAction: 'Proceed with Audio',
    eventLabel: '/intro-video',
    eventInteraction: true,
  },
  'e-audio-2': {
    event: 'customEvent',
    eventCategory: 'In Page Buttons',
    eventAction: 'Proceed without Audio',
    eventLabel: '/intro-video',
    eventInteraction: true,
  },
  'e-intro-video-skip': {
    event: 'customEvent',
    eventCategory: 'In Page Buttons',
    eventAction: 'Skip Intro',
    eventLabel: '/intro',
    eventInteraction: true,
  },
  'e-global-5': {
    event: 'customEvent',
    eventCategory: 'In Page Buttons',
    eventAction: 'Explore Our Tequila',
    eventLabel: '/tequilas',
    eventInteraction: true,
  },
  'e-intro-explore': {
    event: 'customEvent',
    eventCategory: 'In Page Buttons',
    eventAction: 'Explore His Legacy',
    eventLabel: '/devotion/1',
    eventInteraction: true,
  },
  'e-nav-side-top': {
    event: 'customEvent',
    eventCategory: 'Top Navigation',
    eventAction: 'Chapter <Chapter Index>',
    eventLabel: '<Chapter pagePath>',
    eventInteraction: true,
  },
  'e-nav-side-sub': {
    event: 'customEvent',
    eventCategory: 'Top Navigation',
    eventAction: 'Chapter <Chapter Index> - <Scene Index>',
    eventLabel: '<Scene pagePath>',
    eventInteraction: true,
  },
  'e-chapter-video-view': {
    event: 'customEvent',
    eventCategory: 'Video',
    eventAction: 'Playing <0|25|50|75|100>%',
    eventLabel: 'Video - <Chapter Index>',
  },
  'e-chapter-video-pause': {
    event: 'customEvent',
    eventCategory: 'Video',
    eventAction: 'pause',
    eventLabel: 'Video - <Chapter Index>',
    eventInteraction: true,
  },
  'e-chapter-video-play': {
    event: 'customEvent',
    eventCategory: 'Video',
    eventAction: 'play',
    eventLabel: 'Video - <Chapter Index>',
    eventInteraction: true,
  },
  'e-chapter-skip': {
    event: 'customEvent',
    eventCategory: 'In Page Buttons',
    eventAction: 'Skip Video',
    eventLabel: '<Current pagePath>',
    eventInteraction: true,
  },
  'e-carousel-open': {
    event: 'customEvent',
    eventCategory: 'In Page Buttons',
    eventAction: 'Carousel View More',
    eventLabel: '<Current pagePath>',
    eventInteraction: true,
  },
  'e-carousel-close': {
    event: 'customEvent',
    eventCategory: 'In Page Buttons',
    eventAction: 'Carousel Close',
    eventLabel: '<Current pagePath>',
    eventInteraction: true,
  },
  'e-carousel-previous': {
    event: 'customEvent',
    eventCategory: 'Carousel navigation',
    eventAction: 'Carousel Previous',
    eventLabel: '<Current pagePath>',
    eventInteraction: true,
  },
  'e-carousel-next': {
    event: 'customEvent',
    eventCategory: 'Carousel navigation',
    eventAction: 'Carousel Next',
    eventLabel: '<Current pagePath>',
    eventInteraction: true,
  },
  'e-audio-enter': {
    event: 'customEvent',
    eventCategory: 'In Page Buttons',
    eventAction: 'Audio Listen Now',
    eventLabel: '<Current pagePath>',
    eventInteraction: true,
  },
  'e-bottle-scene-close': {
    event: 'customEvent',
    eventCategory: 'In Page Buttons',
    eventAction: 'Close',
    eventLabel: '/p-tequilas',
    eventInteraction: true,
  },
  'e-bottle-scene-next': {
    event: 'customEvent',
    eventCategory: 'In Page Buttons',
    eventAction: 'Next',
    eventLabel: '/p-tequilas-bottle',
    eventInteraction: true,
  },
  'e-bottle-scene-previous': {
    event: 'customEvent',
    eventCategory: 'In Page Buttons',
    eventAction: 'Previous',
    eventLabel: '/p-tequilas-bottle',
    eventInteraction: true,
  },
  'e-bottle-scene-tequila1': {
    event: 'customEvent',
    eventCategory: 'In Page Buttons',
    eventAction: 'View <index>',
    eventLabel: '<Bottle pagePath>',
    eventInteraction: true,
  },
  'e-bottle-scene-tequila1-1': {
    event: 'customEvent',
    eventCategory: 'WTB_Select_Product',
    eventAction: '<Product Name>',
    eventLabel: 'Don Julio',
    eventInteraction: true,
    dimension13: 'Tequila',
    dimension14: 'Don Julio',
  },
  'e-bottle-scene-tequila1-2': {
    event: 'customEvent',
    eventCategory: 'In Page Buttons',
    eventAction: 'View Our Recipes - <index>',
    eventLabel: '/p-tequilas-bottle',
    eventInteraction: true,
    dimension13: 'Tequila',
    dimension14: 'Don Julio',
  },
};

export const TAGS_TO_CLEAR = ['bypassReason', 'dimension13', 'dimension14', 'eventAction', 'eventCategory', 'eventInteraction', 'eventLabel', 'eventTimeout', 'gatewayAction', 'gatewayVersion'];
