import * as globalMutationTypes from './mutationTypes';

/**
 * Unnamespace the mutation keys for `scope`. Simplifies using namespaced modules
 *
 * @export
 * @param {String} scope
 * @param {Object} types the mutation types object
 * @returns {Object}
 */
export default function getTypesForScope(scope, types = globalMutationTypes) {
  const out = {};
  const pattern = new RegExp(`^${scope}/`);
  Object.keys(types).forEach((type) => {
    out[type] = types[type].replace(pattern, '');
  });
  return out;
}
