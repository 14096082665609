<template>
  <main id="app" :class="{ customCursor }">
    <transition name="fade">
      <Logo class="controls__logo" color="white" v-if="showControls" />
    </transition>
    <router-view v-if="copyLoaded && (loaderMounted || isSandbox)" />
    <transition name="fade">
      <div class="controls__overlay" v-if="showOverlay" />
    </transition>
    <transition name="fade-slower">
      <Loader
        ref="loader"
        :loadValue="loadPerc"
        @backgroundLoaded="loaderMounted = true;"
        v-if="!isSandbox && showLoader && copyLoaded"
      />
    </transition>
    <transition name="fade">
      <ChapterNav
        v-if="copyLoaded && !endScene && showControls"
        :controls="$refs.controls"
        :content="content"
        :ready="canvasReady"
        :modalOpen="showModal"
        @showOverlay="handleOverlay"
        ref="chapterNav"
      />
    </transition>
    <transition name="fade">
      <PulseButton
        class="control control--explore"
        v-if="!endScene && showControls"
        :hoverColor="colors[uiSceneIndex]"
        :color="colors[uiSceneIndex]"
        :active="showOverlay"
        copy="EXPLORE OUR TEQUILA"
        @click="handleExploreClick"
        :icon="CaretRight"
        :disabled="sceneTransitioning"
      />
    </transition>
    <transition name="fade">
      <div class="controls__video" v-if="!canvasActive && videoActive && showControls">
        <VideoButton
          :icon="!videoPlaying ? pauseIcon : playIcon"
          :class="{ pause: videoPlaying, play: !videoPlaying }"
          :color="colors[uiSceneIndex]"
          @click="handlePlayClick"
        />
        <VideoButton
          class="control"
          :icon="skipIcon"
          :disabled="!canvasReady"
          :color="colors[uiSceneIndex]"
          @click="handleSkipClick"
        />
        <div class="progress">
          <div class="progress__line" :style="progressStyle" />
        </div>
      </div>
    </transition>
    <div class="controls__buttons" v-if="!endScene && showControls && copyLoaded">
      <CaptionButton class="control" :content="content" :color="colors[uiSceneIndex]" />
      <AudioButton class="control" :content="content" :color="colors[uiSceneIndex]" />
    </div>
    <CaptionBar v-show="showControls" />
    <transition name="fade-quick" @after-leave="clearModal">
      <ModalManager
        v-if="showModal"
        :type="modalType"
        :content="modalContent"
      />
    </transition>
    <transition name="fade">
      <RecipeTabs
        :recipes="activeRecipes.recipes"
        :tequila-name="activeRecipes.name"
        v-if="activeRecipes && activeRecipes.name"
        @close="handleHideRecipes"
      />
    </transition>
    <transition
      name="fade"
      @after-enter="readyNextChapter"
      @after-leave="startNextChapter"
    >
      <ChapterLoader v-if="chapterLoading" />
    </transition>
    <transition name="fade-quick">
      <RoadBlock v-if="roadblockVisible" />
    </transition>
    <div class="footer-wrapper" :class="{ visible: showFooter }">
      <div class="footer-container"
        :class="{
          visible: showFooter,
          hidden: !footerVisible || !appendedLogo,
        }"
        ref="footerContainer"
      >
        <button
          class="footer-container__button hoverable"
          :class="{ white: endScene }"
          @click="handleFooterClick"
        >
          <p class="footer-container__button__copy">Legal</p>
          <span alt="" class="footer-container__button__icon" v-html="CaretRight" />
        </button>
        <div id="footer" />
      </div>
    </div>
    <span alt="" class="footer-logo hidden" ref="footerLogo" v-html="LogoSvg" />
  </main>
</template>

<script>
/* eslint radix: ["error", "as-needed"] */
import { mapActions, mapGetters, mapState } from 'vuex';
import { defineAsyncComponent, provide, configureCompat } from 'vue';
import { gsap } from 'gsap';
import MediaListeners from '@/core/mediaListeners';
import LogoSvg from '@/assets/svg/logo.svg';
import CaretRight from '@/assets/svg/caretRight.svg';
import Loader from '@/components/Loader.vue';
import { SET_SHOW_DEBUG } from '@/store/mutationTypes';
import {
  APP_EVENTS,
  VIDEO_EVENTS,
  CANVAS_EVENTS,
  MODAL_TYPES,
  COLOR_BY_INDEX,
} from '@/core/constants';
import eventBus from '@/core/eventBus';
import StoreBuilder from '@/core/storeBuilder';
import Paused from '@/assets/svg/paused.svg';
import Playing from '@/assets/svg/playing.svg';
import Skip from '@/assets/svg/skipIcon.svg';
import AgeGateLogo from '@/assets/images/age-gate-logo.png';
import ModalManager from '@/components/ModalManager.vue';
import RoadBlock from '@/components/Roadblock.vue';
import Tracking from '@/core/tracking';

configureCompat({ ATTR_FALSE_VALUE: false });

export default {
  name: 'app',
  components: {
    Loader,
    ModalManager,
    ChapterLoader: defineAsyncComponent(() => import('./components/ChapterLoader.vue')),
    RoadBlock,
    AudioButton: defineAsyncComponent(() => import('./components/AudioButton.vue')),
    CaptionButton: defineAsyncComponent(() => import('./components/CaptionButton.vue')),
    Logo: defineAsyncComponent(() => import('./components/Logo.vue')),
    CaptionBar: defineAsyncComponent(() => import('./components/CaptionBar.vue')),
    ChapterNav: defineAsyncComponent(() => import('./components/ChapterNav.vue')),
    VideoButton: defineAsyncComponent(() => import('./components/VideoButton.vue')),
    PulseButton: defineAsyncComponent(() => import('./components/PulseButton.vue')),
    RecipeTabs: defineAsyncComponent(() => import('./components/RecipeTabs.vue')),
  },
  data: () => ({
    assetsLoaded: 0,
    totalAssetsToLoad: 0,
    ageGateChecks: 0,
    showLoader: true,
    loaderComplete: false,
    customCursor: false,
    modalContent: null,
    modalType: '',
    showModal: false,
    chapterLoading: false,
    showFooter: false,
    loaderMounted: false,
    footerVisible: false,
    appendedLogo: false,
    showControls: false,
    canvasReady: true,
    showOverlay: false,
    CaretRight,
    LogoSvg,
  }),
  setup() {
    const tracking = new Tracking();
    provide('tracking', tracking);
    return { tracking };
  },
  created() {
    this.mediaListeners = new MediaListeners(this.$store);
    this.mediaListeners.init();
    eventBus.on(APP_EVENTS.ASSET_LOADED, this.handleAssetLoaded);
    eventBus.on(VIDEO_EVENTS.VIDEO_LOADED, this.handleVideoLoaded);
    eventBus.on(APP_EVENTS.SET_ASSET_COUNT, this.handleSetAssetCount);
    eventBus.on(APP_EVENTS.CHAPTER_CHANGE, this.handleChapterChange);
    eventBus.on(APP_EVENTS.RETURN_TO_START, this.handleResetLanding);
    eventBus.on(APP_EVENTS.SHOW_MODAL, this.handleShowModal);
    eventBus.on(APP_EVENTS.HIDE_MODAL, this.handleHideModal);
    eventBus.on(APP_EVENTS.SHOW_LOADER, () => { this.showLoader = true; });
    eventBus.on(APP_EVENTS.SHOW_FOOTER, (bool) => { this.footerVisible = bool; });
    eventBus.on(APP_EVENTS.LANDING_READY, () => { this.chapterLoading = false; });
    eventBus.on(APP_EVENTS.SHOW_CONTROLS, (bool) => { this.showControls = bool; });
    eventBus.on(CANVAS_EVENTS.CANVAS_READY, (bool) => { this.canvasReady = bool; });
    eventBus.on(APP_EVENTS.REMOVE_LOADER, () => { this.loaderComplete = true; });
    this.storeBuilder = new StoreBuilder({ store: this.$store });
    window.addEventListener('click', this.handleUserInteract);
    window.addEventListener('touchstart', this.handleUserInteract);
  },
  watch: {
    isSandbox(val) {
      this.showControls = val;
    },
    loaderComplete() {
      this.setAppLoaded();
      if (!this.ageGateShown) this.showLanding();
    },
    showFooter(val) {
      if (val) {
        setTimeout(() => {
          document.addEventListener('click', this.checkClickTarget);
        }, 100);
      } else {
        document.removeEventListener('click', this.checkClickTarget);
      }
    },
    isTouchDevice: {
      immediate: true,
      handler(val) {
        if (val) {
          document.body.classList.add('touch');
        }
      },
    },
  },
  computed: {
    ...mapState([
      'debug',
      'appStarted',
      'audioMuted',
      'skipClick',
      'endScene',
      'copyLoaded',
      'activeSceneIndex',
      'sceneTransitioning',
      'uiSceneIndex',
      'roadblockVisible',
      'resetApp',
      'activeRecipes',
    ]),
    ...mapState('canvas', ['canvasActive']),
    ...mapState('video', ['videoPlaying', 'videoCompletePercent', 'videoActive', 'currentVideoIndex']),
    ...mapGetters(['isTouchDevice', 'getContentByPath', 'displayIndex']),
    pauseIcon: () => Paused,
    playIcon: () => Playing,
    skipIcon: () => Skip,
    colors: () => COLOR_BY_INDEX,
    content() {
      return this.getContentByPath('app.global');
    },
    progressStyle() {
      const val = this.videoCompletePercent < 0 ? 0 : this.videoCompletePercent;
      return {
        transform: `translateY(-50%) scaleX(${val / 100})`,
      };
    },
    isSandbox() {
      return this.$route.name === 'Sandbox';
    },
    loadPerc() {
      if (!this.totalAssetsToLoad) return 0;
      const value = Math.floor((this.assetsLoaded / this.totalAssetsToLoad) * 100);
      return value;
    },
  },
  mounted() {
    window.addEventListener('click', this.handleInteract);
    this.observer = new MutationObserver(this.checkAgeGate);
    this.footerObserver = new MutationObserver(this.checkFooter);
    this.footerObserver.observe(document.getElementById('footer'), { childList: true });
    this.observer.observe(document.body, { childList: true });
    this.$nextTick(() => { this.checkAgeGate(); });
  },
  methods: {
    ...mapActions(['setAppLoaded', 'setAppStarted', 'setAppLoaded', 'setModalOpen', 'setResetApp', 'setSkipClick']),
    ...mapActions('video', ['setVideoRender', 'setVideoIndex', 'setResetVideo']),
    ...mapActions('canvas', ['setCanvasActive', 'setCanvasRender', 'setCanvasIndex', 'setCanvasIgnoreOut']),
    ...mapActions(['setActiveSceneIndex', 'setUserInteracted', 'setUISceneIndex', 'setActiveRecipes']),
    handleOverlay(bool) { this.showOverlay = bool; },
    handleHideRecipes() {
      this.setActiveRecipes(null);
    },
    handleMouseEnter(e) { eventBus.emit(APP_EVENTS.MOUSE_ENTER, e); },
    handleMouseLeave() { eventBus.emit(APP_EVENTS.MOUSE_LEAVE); },
    handlePlayClick() {
      const id = this.videoPlaying ? 'e-chapter-video-pause' : 'e-chapter-video-play';
      this.tracking.trackEvent(id, { eventLabel: `Video - ${this.displayIndex}` });
      if (this.videoPlaying) {
        eventBus.emit(VIDEO_EVENTS.PAUSE_VIDEO);
      } else {
        eventBus.emit(VIDEO_EVENTS.PLAY_VIDEO);
      }
    },
    handleSkipClick() {
      this.setSkipClick(true);
      eventBus.emit(VIDEO_EVENTS.SKIP_VIDEO);
      this.$refs.chapterNav.expanded = false;
      this.handleOverlay(false);
      setTimeout(() => {
        this.handleOverlay(false);
        this.setSkipClick(false);
      }, 500);
    },
    handleReplayClick() {
      eventBus.emit(APP_EVENTS.RETURN_TO_START);
    },
    handleExploreClick() {
      this.tracking.trackEvent('e-global-5');
      eventBus.emit(VIDEO_EVENTS.PAUSE_VIDEO);
      eventBus.emit(APP_EVENTS.SHOW_MODAL, { type: MODAL_TYPES.END_SCENE });
    },
    handleFooterClick() {
      this.showFooter = !this.showFooter;
      eventBus.emit(APP_EVENTS.MOUSE_LEAVE);
    },
    handleUserInteract() {
      this.setUserInteracted(true);
      window.removeEventListener('click', this.handleUserInteract);
      window.removeEventListener('touchstart', this.handleUserInteract);
    },
    checkClickTarget(e) {
      if (!this.$refs.footerContainer.contains(e.target)) {
        this.showFooter = false;
      }
    },
    checkFooter() {
      const footer = document.querySelector('#dg_footer_wrapper');
      if (footer && !this.appendedLogo) {
        this.appendedLogo = true;
        const logo = this.$refs.footerLogo.cloneNode(true);
        logo.classList.remove('hidden');
        footer.querySelector('ul').appendChild(logo);
      }
    },
    checkAgeGate() {
      if (this.changeTimeout) clearTimeout(this.changeTimeout);
      const ageGate = document.querySelector('#age_gate');
      if (!ageGate) {
        this.changeTimeout = setTimeout(() => {
          // no dom changes for 1.5 seconds assume no age gate
          if (this.$refs.loader) this.$refs.loader.playAnimation();
        }, 1500);
      }
      if (ageGate && !this.ageGateShown) {
        this.ageGateShown = true;
        this.observer.disconnect();
        this.observer.observe(ageGate, { attributes: true, childList: true });
      } else if (this.ageGateShown && ageGate.style.display === 'none') {
        this.observer.disconnect();
        this.ageGateShown = false;
        this.$refs.loader.playAnimation();
      } else if (this.ageGateShown && ageGate.querySelector('#age_content')) {
        this.styleAgeGate();
      }
    },
    styleAgeGate() {
      gsap.set('#age_content', { background: '#F1F5E8' });
      document.querySelector('#age_logo_img').src = AgeGateLogo;
    },
    handleInteract() {
      window.removeEventListener('click', this.handleInteract);
    },
    handleDebug() {
      this.$store.commit(SET_SHOW_DEBUG, !this.debug);
    },
    showLanding() {
      this.setAppStarted(true);
      this.$nextTick(() => {
        this.showLoader = false;
      });
    },
    handleVideoLoaded() {
      if (this.chapterLoading) {
        this.chapterLoading = false;
        eventBus.emit(VIDEO_EVENTS.SEEK_VIDEO);
      } else {
        this.handleAssetLoaded();
      }
    },
    handleAssetLoaded() {
      this.assetsLoaded += 1;
    },
    handleSetAssetCount(num) {
      this.totalAssetsToLoad += num;
    },
    handleShowModal({ type, content }) {
      this.modalType = type;
      this.modalContent = content;
      this.showModal = true;
      this.setModalOpen(true);
    },
    handleHideModal() {
      this.showModal = false;
      this.setModalOpen(false);
    },
    clearModal() {
      this.modalType = '';
      this.modalContent = null;
    },
    handleChapterChange(i) {
      if (i === this.activeSceneIndex) {
        eventBus.emit(CANVAS_EVENTS.FADE_CANVAS, false);
        this.setResetVideo(true);
      }
      if (this.videoPlaying) {
        eventBus.emit(VIDEO_EVENTS.PAUSE_VIDEO);
      }
      eventBus.emit(VIDEO_EVENTS.SET_OFFSET_VIDEO, true);
      this.chapterLoading = true;
      this.nextChapter = i;
    },
    readyNextChapter() {
      if (this.resetApp) {
        eventBus.emit(APP_EVENTS.READY_LANDING);
        this.setVideoRender(false);
        this.setCanvasRender(false);
        this.setCanvasActive(false);
        this.setVideoIndex(0);
        this.setCanvasIndex(0);
      } else {
        this.setCanvasIgnoreOut(true);
        eventBus.emit(CANVAS_EVENTS.FADE_CANVAS, false);
        this.setCanvasRender(false);
        this.setCanvasActive(false);
        this.setVideoRender(false);
        if (this.nextChapter === this.activeSceneIndex) {
          eventBus.emit(CANVAS_EVENTS.RESET_TIMELINE);
        }
        if (this.currentVideoIndex === this.nextChapter) {
          setTimeout(() => {
            eventBus.emit(VIDEO_EVENTS.LOAD_VIDEO);
          }, 500);
        }
        this.setVideoIndex(this.nextChapter);
        this.setCanvasIndex(this.nextChapter);
        this.setActiveSceneIndex(this.nextChapter);
        this.setUISceneIndex(this.nextChapter);
      }
    },
    startNextChapter() {
      if (!this.resetApp) {
        // eventBus.emit(VIDEO_EVENTS.PLAY_VIDEO);
        this.setResetVideo(false);
      } else {
        this.setResetApp(false);
        eventBus.emit(APP_EVENTS.START_LANDING);
      }
    },
    handleResetLanding() {
      this.setResetApp(true);
      this.chapterLoading = true;
    },
  },
};
</script>

<style lang="scss">
@import './styles/_normalize';
@import './styles/_scaffold';
#age_content {
  background-blend-mode: multiply;
  .age_header_title, .age_birth_title_label, .age_privacy, .age_remember_me_basic {
    font-family: $bodyFont;
    color: $black;
  }
  .age_header_title {
    text-transform: uppercase;
  }
  #age_logo_img {
    width: 74px;
  }
  #age_age_select_group {
    width: 400px !important;
    #age_age {
      display: flex;
      width: 100%;
      select {
        background: none;
        text-transform: uppercase;
        font-family: $bodyFont;
        padding: 5px 10px;
        font-size: 12px;
        border-radius: 3px;
        border: 1px solid $black;
        margin-left: 10px;
      }
    }
  }
  .age_privacy {
    text-align: center;
    max-width: 380px;
    margin: 20px auto !important;
    line-height: 16px;
    a {
      color: $black !important;
    }
  }
  .age_tnc_link, #show_selected_country {
    color: $black !important;
    font-family: $bodyFont;
  }
  #age_remember_label {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .age_remember_me_basic {
    color: $black !important;
    font-size: 12px;
    margin-left: 8px;
  }
  #age_confirm {
    #age_confirm_btn {
      max-width: 340px;
      min-width: 340px;
      border: none !important;
      background: #E87722 !important;
      color: white !important;
      font-family: $bodyFont;
      text-transform: uppercase;
      border-radius: 8px !important;
      letter-spacing: 1px;
      transition: background 350ms $easeOutMaterial;
      @include on-hover {
        background: darken(#E87722, 10) !important;
      }
    }
  }
}
#app {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  background-color: #EAE6E3;
  &.customCursor {
    * {
      cursor: none;
    }
  }
  .debug {
    position: fixed;
    top: 50px;
    left: 50px;
    background: black;
    color: white;
    z-index: 1000;
  }
  .footer-container {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: transform 350ms $easeOutMaterial,
                opacity 350ms $easeOutMaterial;
    &.hidden {
      opacity: 0;
      pointer-events: none;
    }
    z-index: 1000;
    transform: translateY(100%);
    &__button {
      position: absolute;
      left: 30px;
      top: -52px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 22px;
      padding: 0 2px 4px;
      border: none;
      border-bottom: 1px solid rgba(#040707, 0.4);
      background: none;
      cursor: pointer;
      @include bpLarge {
        left: 50px;
      }
      &.white {
        .footer-container__button {
          &__copy {
            color: white;
          }
          &__icon {
            stroke: white;
          }
        }
      }
      &__copy {
        color: black;
        font-size: 12px;
        margin: 0;
        margin: 0 7px 2px 0;
      }
      &__icon {
        transform: rotate(-90deg);
        transition: transform 350ms $easeOutMaterial;
        stroke: #040707;
      }
    }
    &.visible {
      transform: translateY(0%);
      pointer-events: initial;
      .footer-container__button {
        &__icon {
          transform: rotate(90deg);
        }
      }
    }
  }
  #footer {
    width: 100%;
    #dg_footer_wrapper {
      background: $black;
      .dg_footer_row_0 {
        padding: 18px 0;
      }
    }
    #dg_footer_wrapper_inner {
      width: 100%;
    }
    #dg_footer_wrapper_inner ul {
      padding: 0 30px;
      width: auto;
      display: grid;
      display: grid;
      grid-template-columns: 50% 50%;
      grid-template-rows: auto auto auto auto auto auto auto;
      gap: 0px 0px;
      grid-template-areas:
        "logo ."
        "middle middle"
        "bottom bottom"
        "area-0 area-4"
        "area-1 area-5"
        "area-2 area-6"
        "area-3 area-7";
      justify-content: space-between;
      @media screen and (min-width: 700px) {
        display: grid;
        grid-template-columns: minmax(200px, 30%) .25fr auto auto auto auto;
        grid-template-rows: auto auto auto;
        gap: 0px 0px;
        grid-template-areas:
          "logo . . . . ."
          "left-middle . area-0 area-1 area-2 area-3"
          "left-bottom . area-4 area-5 area-6 area-7";
        justify-content: space-between;
      }
      @media screen and (min-width: 1080px) {
        padding: 0 50px;
        grid-template-columns: minmax(200px, 25%) .55fr auto auto auto auto auto auto auto auto;
        grid-template-rows: auto auto auto;
        gap: 0px 0px;
        grid-template-areas:
          "logo . . . . . . . . ."
          "left-middle . area-0 area-1 area-2 area-3 area-4 area-5 area-6 area-7"
          "left-bottom . area-0 area-1 area-2 area-3 area-4 area-5 area-6 area-7";
        justify-content: space-between;
      }
      li:after {
        display: none;
      }
      li {
        line-height: 12px;
        text-align: left;
        margin: 5px 5px 5px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @for $i from 0 through 7 {
          &.#{'dg_footer_item_' + $i} {
            grid-area: area-#{$i};
          }
        }
        @media screen and (min-width: 700px) {
          @for $i from 0 through 7 {
            &.#{'dg_footer_item_' + $i} {
              justify-content: flex-start;
              align-items: center;
              padding: 0 5px;
              white-space: nowrap;
              white-space: nowrap;
              grid-area: area-#{$i};

            }
          }
        }
        @media screen and (min-width: 1080px) {
          @for $i from 0 through 7 {
            &.#{'dg_footer_item_' + $i} {
              grid-area: area-#{$i};
              display: flex;
            }
          }
        }
      }
      .dg_footer_item_8 {
        grid-area: middle;
        margin: 0;
        @media screen and (min-width: 700px) {
          grid-area: left-middle;
        }
      }
      .dg_footer_item_last {
        grid-area: bottom;
        margin: 0;
        margin-bottom: 20px;
        @media screen and (min-width: 700px) {
          grid-area: left-bottom;
        }
      }
      .footer-logo {
        grid-area: logo;
        svg { fill: white; }
        margin-bottom: 10px;
        width: 75px;
        &.hidden {
          position: absolute;
          left: -100%;
          bottom: -100%;
        }
      }
    }
    #dg_footer_wrapper_inner .dg_footer_row_0 a,
    #dg_footer_wrapper_inner .dg_footer_row_0 a:hover,
    #dg_footer_wrapper_inner ul li span {
      color: white;
      font-family: $bodyFont;
      font-size: 8px;
      line-height: 10px;
      padding: 0;
    }
  }
  .control {
    z-index: 4;
    &--explore {
      position: absolute;
      top: 20px;
      right: 20px;
      @include bpMedium {
        right: 50px;
      }
      svg {
        stroke: white;
      }
    }
    &--play {
      width: 30px;
      height: 30px;
    }
  }
  .footer-logo {
    grid-area: logo;
    fill: white;
    margin-bottom: 10px;
    width: 75px;
    svg { fill: white; }
    &.hidden {
      position: absolute;
      left: -100%;
      bottom: -100%;
    }
  }
  .progress {
    width: 100%;
    background: rgba(white, 0.4);
    position: fixed;
    bottom: 0;
    left: 0;
    height: 4px;
    @include bpMedium {
      height: 1px;
      position: relative;
      margin: 0 30px;
    }
    &__line {
      position: absolute;
      top: 50%;
      left: 0;
      transform-origin: left center;
      transform: translateY(-50%) scaleX(0);
      background: white;
      height: 100%;
      width: 100%;
    }
  }
  .controls {
    &__overlay {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 3;
      background: rgba(0, 0, 0, 0.8);
      pointer-events: initial;
    }
    &__logo {
      position: absolute;
      z-index: 10;
      pointer-events: none;
    }
    &__video {
      position: absolute;
      bottom: 20px;
      left: 15px;
      display: flex;
      align-items: center;
      z-index: 4;
      width: 100%;
      @include bpMedium {
        width: calc(100% - 145px);
        bottom: 20px;
        left: 30px;
      }
    }
    &__buttons {
      position: absolute;
      bottom: 20px;
      right: 15px;
      display: flex;
      z-index: 4;
      @include bpMedium {
        bottom: 20px;
        right: 30px;
      }
    }
  }
}
</style>
