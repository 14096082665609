<template>
  <div class="spinner">
    <svg class="animate" viewBox="25 25 50 50">
      <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" />
    </svg>
    <svg viewBox="25 25 50 50">
      <circle cx="50" cy="50" r="20" fill="none" stroke-width="1" />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'SpinnerComponent',
};
</script>

<style lang="scss">
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124;
  }
}
.spinner {
  border-radius: 60px;
  margin: -5px auto;
  width: 50px;
  height: 50px;
  position: relative;
  svg {
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    circle {
      stroke: rgba(white, 0.5);
    }
    &.animate {
      animation: rotate 2s linear infinite;
      .path {
        stroke: white;
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
        animation: dash 1.5s ease-in-out infinite;
        stroke-linecap: round;
      }
    }
  }
}
</style>
