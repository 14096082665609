<template>
  <button
    class="video-button"
    :class="[color, { disabled }]"
    @click="handleClick"
  >
    <span class="video-button__icon" v-html="icon" />
  </button>
</template>

<script>
import { APP_EVENTS } from '@/core/constants';
import eventBus from '@/core/eventBus';

export default {
  name: 'video-button',
  props: {
    icon: { required: true },
    color: { required: true },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick() {
      this.$emit('click');
    },
    handleEnter(e) {
      eventBus.emit(APP_EVENTS.MOUSE_ENTER, e);
    },
    handleLeave() {
      eventBus.emit(APP_EVENTS.MOUSE_LEAVE);
    },
  },
};
</script>

<style lang="scss">
.video-button {
  position: relative;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 7.5px;
  width: 45px;
  height: 45px;
  flex-shrink: 0;
  margin-left: 0;
  pointer-events: initial;
  border: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  &:first-of-type {
    margin-left: -7.5px;
  }
  &:last-of-type {
    margin-right: -7.5px;
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  @include on-hover {
    &::after {
      transform: scale(1.2);
      opacity: 0;
    }
    &::before {
      transform: scale(1);
      opacity: 1;
    }
  }
  &::after, &::before {
    content: '';
    position: absolute;
    width: 30px;
    height: 30px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    transform-origin: center center;
    border-radius: 50%;
    transition: opacity 250ms $easeOutMaterial,
                transform 250ms $easeOutMaterial;
    z-index: -1;
  }
  &::before {
    border: none;
    width: 30px;
    height: 30px;
    background-blend-mode: multiply;
    border-radius: 50%;
    transform: scale(0.7);
    opacity: 0;
    background-position: center;
    transition: opacity 250ms $easeOutMaterial,
                transform 250ms $easeOutMaterial;
  }
  &.red { &::before { background-image: url('../assets/images/red-texture.png'); } }
  &.blue { &::before { background-image: url('../assets/images/blue-texture.png'); } }
  &.teal { &::before { background-image: url('../assets/images/teal-texture.png'); } }
  &.orange { &::before { background-image: url('../assets/images/orange-texture.png'); } }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.pause {
    svg {
      width: 18%;
      margin-left: 0;
    }
  }
  &.play {
    svg {
      width: 28%;
      margin-left: 2px;
    }
  }
  svg {
    width: 38%;
    pointer-events: none;
    margin-left: 1px;
    path, rect {
      fill: white;
    }
  }
  &.disabled {
    pointer-events: none;
    svg {
      path, rect {
        fill: grey;
      }
    }
  }
}
</style>
